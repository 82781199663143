import EventProductPage from "@bottlebooks/bottlebooks-site-base/src/components/EventProductPage/EventProductPage.next";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { z } from "zod";
import { graphql, useFragment } from "~/gql";
import graphQLClient from "~/graphQLClient";
import getCollectionId from "~/pages/[locale]/_getCollectionId";
import getCustomDomain from "~/pages/[locale]/_getCustomDomain";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  exhibitorId: z.string(),
  productId: z.string(),
});

const fragment = graphql(/* GraphQL */ `
  fragment ProductDetail on RegisteredProduct {
    registrationId
    exhibitorId
    producerId
    productId
    product {
      producer {
        name
        logo {
          publicId
        }
      }
    }
    productId
    producer {
      profile {
        name
      }
      products {
        nodes {
          productId
        }
      }
    }
    product {
      productId
    }
    ...EventProductPage_RegisteredProduct
  }
`);

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader(args: LoaderFunctionArgs) {
  const { exhibitorId, productId, locale } = paramsSchema.parse(args.params);
  const customDomain = getCustomDomain(args);
  const result = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query ProductPageByCustomDomain(
        $customDomain: String!
        $companyId: ID!
        $productId: ID!
        $locale: ContentLocale
      ) {
        collection: _unstable_collectionByCustomDomain(
          experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
          customDomain: $customDomain
          locale: $locale
        ) {
          collectionId
          registrations(filter: { companyId: { eq: $companyId } }) {
            nodes {
              __typename
              registrationId
              ... on SingleRegistration {
                registeredProducts(filter: { productId: { eq: $productId } }) {
                  nodes {
                    ...ProductDetail
                  }
                }
              }
            }
          }
        }
      }
    `),
    {
      customDomain,
      companyId: exhibitorId,
      productId,
      locale,
      // Required to access to cached data.
      collectionId: await getCollectionId({ customDomain }),
    },
    { "bottlebooks-use-request-cache": "true" }
  );
  /**
   * It is possible that an exhibitor has multiple registrations.
   * This is currently limited to collections with childCollections.
   * But it could eventually happen in the future. So we need to handle it.
   */
  const registrations = result.collection?.registrations.nodes;
  if (!registrations?.length) throw new Error("No registration found.");
  const registrationsWithProducts = registrations.filter(
    (registration) =>
      registration.__typename === "SingleRegistration" &&
      registration.registeredProducts?.nodes?.length
  );
  if (!registrationsWithProducts?.length)
    throw new Error("No registration found with products.");
  const singleRegistrations = registrationsWithProducts
    .map((registration) =>
      registration.__typename === "SingleRegistration" ? registration : null
    )
    .filter((registration) => registration !== null);
  if (!singleRegistrations?.length)
    throw new Error("No single registration found with products.");
  const singleRegistrationsWithProduct = singleRegistrations.filter(
    (registration) => registration.registeredProducts?.nodes?.length
  );
  if (!singleRegistrationsWithProduct?.length)
    throw new Error("No single registration found.");
  const registeredProduct =
    singleRegistrationsWithProduct[0].registeredProducts?.nodes[0];
  if (!registeredProduct) throw new Error("No registered product found.");
  return registeredProduct;
}

export default function ProductPageTemplate() {
  const data = useLoaderData() as LoaderData;
  const registeredProduct = useFragment(fragment, data);
  return (
    <EventProductPage
      key={`${registeredProduct.registrationId}-${registeredProduct.productId}`}
      eventId={data.collectionId}
      data={registeredProduct}
      product={{ ...registeredProduct, ...registeredProduct.product }}
      producer={{
        ...registeredProduct.producer?.profile,
        // This isn't pretty. It is because PresentedBrands is shared between Exhibitor and Product pages.
        registeredBrands: { nodes: [registeredProduct.producer] },
      }}
      otherProducts={registeredProduct.producer?.products.nodes?.filter(
        (product) => product.productId !== registeredProduct.productId
      )}
      otherProductsName={registeredProduct.producer?.profile?.name}
      relatedProducts={[]}
      // TODO: implement previous and next for paging through products.
      previous={undefined}
      next={undefined}
    />
  );
}
