/** @jsxImportSource theme-ui */
import { Button, DownloadIcon } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { z } from "zod";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment RegistrationInfoSheetButton on SingleRegistration {
    collectionId
    collection {
      site {
        templateSettings {
          name
          value
        }
      }
    }
    companyId
    name
  }
`);

const varsSchema = z
  .array(
    z.object({
      name: z.string(),
      value: z.string(),
    })
  )
  // Transform to object
  .transform((vars) =>
    vars.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {})
  );

const siteConfigSchema = z.object({
  registrationSheetTemplateId: z
    .string()
    .optional()
    .default("brn:print:bottlebooks:me.bottlebooks.standardCatalog2"),
});

export default function RegistrationInfoSheetButton({
  exhibitor: data,
  ...rest
}: {
  exhibitor: FragmentType<typeof fragment>;
}) {
  const registration = useFragment(fragment, data);
  const siteConfig = siteConfigSchema.parse(
    varsSchema.parse(registration.collection?.site?.templateSettings ?? [])
  );
  return (
    <Button
      href={`https://app.bottlebooks.me/pdf/reg/${registration.collectionId}/${
        registration.companyId
      }/t/${siteConfig.registrationSheetTemplateId}/f/${encodeURIComponent(
        registration.name
      )}?inline=1`}
      target="_blank"
      rel="noopener noreferrer"
      variant="outline"
      sx={{ textAlign: "center" }}
      {...rest}
    >
      <DownloadIcon
        size="small"
        sx={{ marginRight: 2, color: "currentColor" }}
      />
      <Trans>Download info sheet</Trans>
    </Button>
  );
}
